.markdown-wrapper {
  a {
    color: #383838;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  table {
    margin: 1em;
    transition: all .3s;
    th,td {
      background-color: transparent;
      border-bottom: #383838 solid 1px;
      padding: .5em 1em .5em .5em;
    }
    tr:hover {
      th,td {
        background-color: #d1d1d1;
      }
    }
  }
}