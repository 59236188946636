.BreadCrumb {
  &-wrapper {
    display: block;
    list-style: none;
    margin: .5em 0;
    padding: 0;
  }
  &-item {
    display: inline;
    &:first-child {
      &::before {
        content: url("./../../../common/assets/common/home16x16.jpeg");
        display: inline-block;
        margin: 0 .5em;
        transform: translateY(.2em);
      }
    }
    &:not(:last-child) {
      &::after {
        content: ">";
        display: inline-block;
        margin: 0 .5em;
      }
    }
  }
  &-link,
  &-currentPage {
    &,
    &:hover,
    &:visited {
      color: #4a4a4a;
      text-decoration: none;
    }
  }
  &-link {
    &:hover {
      text-decoration: underline;
    }
  }
}