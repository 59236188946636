.footer {
    &-nav {
        .nav-link {   
            padding: 0!important;
            &::before {
                content: "|";
                display:  inline-block;
                margin: 0 1em;
            }
            &:last-child {
                &::after {
                    content: "|";
                    display:  inline-block;
                    margin: 0 1em;
                }
            }
        }
    }
}